import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`}             component={lazy(() => import(`./home`))         } />
        <Route path={`${APP_PREFIX_PATH}/answer-test/:id`}  component={lazy(() => import(`./answer-test`)) } />
        <Route path={`${APP_PREFIX_PATH}/users`}            component={lazy(() => import(`./users`))        } />
        <Route path={`${APP_PREFIX_PATH}/institutions`}     component={lazy(() => import(`./institutions`)) } />
        <Route path={`${APP_PREFIX_PATH}/staff`}            component={lazy(() => import(`./staff`))        } />
        <Route path={`${APP_PREFIX_PATH}/students`}         component={lazy(() => import(`./students`))     } />
        <Route path={`${APP_PREFIX_PATH}/skills`}           component={lazy(() => import(`./skills`))       } />
        <Route path={`${APP_PREFIX_PATH}/reports`}          component={lazy(() => import(`./reports`)) } />
        <Route path={`${APP_PREFIX_PATH}/subjects`}         component={lazy(() => import(`./subjects`)) } />
        <Route path={`${APP_PREFIX_PATH}/correcoes`}         component={lazy(() => import(`./proofread`)) } />
        <Route path={`${APP_PREFIX_PATH}/supporting-texts`}         component={lazy(() => import(`./supporting-texts`)) } />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);