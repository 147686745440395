import { 
  FileTextOutlined,
  UserOutlined, 
  TeamOutlined, 
  BookOutlined, 
  CheckOutlined,
  OrderedListOutlined,
  ReadOutlined,
  GlobalOutlined,
  ShopOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [{
  key: 'menu',
  path: `${APP_PREFIX_PATH}/home`,
  title: 'MENU',
  icon: FileTextOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'avaliacoes',
      path: `${APP_PREFIX_PATH}/home`,
      title: 'Avaliações',
      icon: FileTextOutlined,
      breadcrumb: false,
      rules: ['ADMIN', 'ESCOLA', 'CORPO_DOCENTE', 'ALUNO'],
      submenu: []
    },
    {
      key: 'instituicoes',
      path: `${APP_PREFIX_PATH}/institutions`,
      title: 'Instituições',
      icon: ShopOutlined,
      breadcrumb: false,
      rules: ['ADMIN', 'CORPO_DOCENTE'],
      submenu: []
    },
    {
      key: 'correcoes',
      path: `${APP_PREFIX_PATH}/correcoes`,
      title: 'Corrigir Avaliações',
      icon: CheckOutlined,
      breadcrumb: false,
      rules: ['ADMIN', 'ESCOLA', 'CORPO_DOCENTE'],
      submenu: []
    },
    {
      key: 'corpo-docente',
      path: `${APP_PREFIX_PATH}/staff`,
      title: 'Corpo Docente',
      icon: TeamOutlined,
      breadcrumb: false,
      rules: ['ADMIN', 'ESCOLA', 'CORPO_DOCENTE'],
      submenu: []
    },
    {
      key: 'estudantes',
      path: `${APP_PREFIX_PATH}/students`,
      title: 'Estudantes',
      icon: BookOutlined,
      breadcrumb: false,
      rules: ['ADMIN', 'ESCOLA', 'CORPO_DOCENTE'],
      submenu: []
    },
    {
      key: 'usuarios',
      path: `${APP_PREFIX_PATH}/users`,
      title: 'Usuários',
      icon: UserOutlined,
      breadcrumb: false,
      rules: ['ADMIN', 'CORPO_DOCENTE'],
      submenu: []
    },
    {
      key: 'habilidades',
      path: `${APP_PREFIX_PATH}/skills`,
      title: 'Habilidades',
      icon: ReadOutlined,
      breadcrumb: false,
      rules: ['ADMIN', 'CORPO_DOCENTE'],
      submenu: []
    },
    {
      key: 'materias',
      path: `${APP_PREFIX_PATH}/subjects`,
      title: 'Disciplinas',
      icon: OrderedListOutlined,
      breadcrumb: false,
      rules: ['ADMIN', 'CORPO_DOCENTE'],
      submenu: []
    },
    {
      key: 'textos-de-apoio',
      path: `${APP_PREFIX_PATH}/supporting-texts`,
      title: 'Textos de Apoio',
      icon: OrderedListOutlined,
      breadcrumb: false,
      rules: ['ADMIN', 'CORPO_DOCENTE'],
      submenu: []
    }
  ]
},
{
  key: 'reports',
  path: `${APP_PREFIX_PATH}/home`,
  title: 'Relatórios',
  icon: FileTextOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'globalized',
      path: `${APP_PREFIX_PATH}/reports/globalized`,
      title: 'Dados Globalizados',
      icon: GlobalOutlined,
      breadcrumb: false,
      rules: ['ADMIN', 'CORPO_DOCENTE'],
      submenu: []
    },
    {
      key: 'dominio-escola',
      path: `${APP_PREFIX_PATH}/reports/school`,
      title: 'Domínio de Habilidades por Escola',
      icon: ShopOutlined,
      breadcrumb: false,
      rules: ['ADMIN', 'CORPO_DOCENTE'],
      submenu: []
    },
    {
      key: 'dominio-aluno',
      path: `${APP_PREFIX_PATH}/reports/student`,
      title: 'Domínio de Habilidades por Aluno',
      icon: UserOutlined,
      breadcrumb: false,
      rules: ['ADMIN', 'CORPO_DOCENTE'],
      submenu: []
    },
    {
      key: 'dominio-turma',
      path: `${APP_PREFIX_PATH}/reports/class`,
      title: 'Domínio de Habilidades por Turma',
      icon: TeamOutlined,
      breadcrumb: false,
      rules: ['ADMIN', 'CORPO_DOCENTE'],
      submenu: []
    },
  ]
}
]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
