import axios from "axios";

// http://localhost:8095/apiClientAuth
const apiClientAuth = axios.create({
  baseURL: process.env.REACT_APP_API_CLIENT_AUTH_BASE,
});

apiClientAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (401 === error.response.status) {
      return Promise.reject(error.response.data);
    } else {
      return Promise.reject(error);
    }
  }
);

export default apiClientAuth;
